import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Valent Childs is `}<a parentName="p" {...{
        "href": "/Estelle%20Sartere",
        "title": "Estelle Sartere"
      }}>{`Estelle Sartere’s`}</a>{` twelve-year old niece and scion of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Soul",
        "title": "Primal Spirit of Soul"
      }}>{`Primal Soul`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      